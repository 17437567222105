<template>
  <form
    class="w-64 ml-auto mr-auto grid gap-4"
    @submit.prevent="submit"
    id="form_user_forgot_password"
  >
    <FormAlert v-if="alert" :message="$t(alert.message)" :type="alert.type" />

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @update:modelValue="$emit('update:email', $event)"
    />

    <input
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full"
      :value="$t('SendPassword')"
      :class="
        isMm()
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
    />
  </form>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()

const props = defineProps<{
  email: string
}>()

const alert = ref<{ message: string; type: AlertType } | undefined>(undefined)

async function submit() {
  alert.value = undefined
  alert.value = await nuxtApp.$api.account.sendPasswordResetEmail(props.email)
}
</script>
