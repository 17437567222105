<template>
  <CommonLoading v-if="loading" class="mx-auto" />
  <form
    v-else
    class="w-64 ml-auto mr-auto grid gap-4"
    @submit.prevent="submit"
    id="form_user_login"
  >
    <FormAlert :type="alertType" :message="alertMessage" />

    <FormAlert v-if="ssoUrl" type="info">
      <span
        class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
        v-html="$t('sso.YourEmailIsOnAnSSOClient', { url: ssoUrl })"
      />
    </FormAlert>

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @blur="checkSSO"
      @update:modelValue="$emit('update:email', $event)"
    />

    <FormPassword
      :modelValue="password"
      placeholder="Password"
      :pattern="false"
      :minlength="false"
      @update:modelValue="$emit('update:password', $event)"
    />

    <input
      data-testid="popup-login-button"
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full"
      :value="$t('Login')"
      :class="
        isMm()
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
    />
    <a v-if="(isSite('altinget_dk') || isMm()) && allowWayf" href="/wayf/">
      <img class="mx-auto py-2 px-4" src="/wayf-logo.png" alt="WAYF login" />
    </a>
    <slot />
  </form>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const userStore = useUserStore()

const emit = defineEmits<{
  (e: 'update:password', value: string): void
  (e: 'update:email', value: string): void
}>()

const loading = ref(false)
const emailinput = ref<HTMLInputElement>()

const props = withDefaults(
  defineProps<{
    email: string
    password: string
    allowWayf?: boolean
  }>(),
  { allowWayf: true }
)

async function submit() {
  loading.value = true
  clearAlert()

  const result = await userStore.loginUser(props.email, props.password)
  if (result.status === 'error') {
    emit('update:password', '')
    if (result.code === 401) {
      setAlert('Wronglogin')
    } else if (result.code === 500) {
      setAlert('ServerError')
      const capturedError = new Error('Internal Server Error: ', {
        cause: result.error,
      })
      if (import.meta.client) {
        nuxtApp.$sentry.captureException(capturedError)
      }
    } else {
      setAlert('DefaultError')
      const capturedError = new Error('Unknown Error: ', {
        cause: result.error,
      })
      if (import.meta.client) {
        nuxtApp.$sentry.captureException(capturedError)
      }
    }
  }
  loading.value = false
}

// Single Sign-On checks
const ssoProviderUrl = ref('')
async function checkSSO() {
  if (props.email && config.public.site.allowSingleSignOn) {
    const ssoProvider = await nuxtApp.$api.user.isSSOEmail(props.email)
    if (ssoProvider) {
      ssoProviderUrl.value = ssoProvider.providerUrl
    } else {
      ssoProviderUrl.value = ''
    }
  }
}

const ssoUrl = computed(() => {
  if (import.meta.server) return ''
  if (ssoProviderUrl.value === '') return ''
  const url = new URL(ssoProviderUrl.value)
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})

// Error handling
const { setAlert, clearAlert, alertType, alertMessage } = useAlert()
</script>
